<template>
<div class="list">

<div class="listMain wrap">
<div class="listMainBox">
<div class="listMainLeft fl">
<div class="tit">{{slideHdTitle}}</div>
<div class="sort">
<div v-for="(item, index) in slideList" :key="index">
<a 
                v-if="item.url && item.url !== ''" 
                target="_blank" 
                :class="{ck : active  == item.entTChannelUuid}"
                :href="item.url">
                {{item.name}}
              </a>
<a 
                v-if="!item.url || item.url == ''" 
                :class="{ck : active  == item.entTChannelUuid}"
                :href="item.catalog === 1 ? `/listPage/${item.entTChannelUuid}` : item.catalog === 2 ? `/list/${item.entTChannelUuid}` : `/listPic/${item.entTChannelUuid}`">
                {{item.name}}
              </a>
</div>
</div>
<!-- <div class="listSlid">
<div class="listSlidHd">
              {{slideHdTitle}}
            </div>
<div class="listSlidList">
<div class="listSlidListInner" :class="{on : active  == item.entTChannelUuid}" v-for="(item, index) in slideList" :key="index">
<a 
                  v-if="item.url && item.url !== ''" 
                  target="_blank" 
                  :href="item.url">
                  {{item.name}}
                </a>
<a 
                  v-if="!item.url || item.url == ''" 
                  :href="item.catalog === 1 ? `/listPage/${item.entTChannelUuid}` : item.catalog === 2 ? `/list/${item.entTChannelUuid}` : `/listPic/${item.entTChannelUuid}`">
                  {{item.name}}
                </a>
<span></span>
</div>
</div>
</div>-->
</div>
<div class="listMainRight fr">
<div class="listWrap">
<div class="listHd clearfloat">
<div class="fl listHdGropL">
<span>{{slideHdName}}</span>
</div>
<div class="fr listHdGrop">
<span class="listHdIco"></span>您当前的位置：<a href="/">首页</a>><span v-if="viewSlideHdParent"><a :href="slideHdParentUrl">{{slideHdParentName}}</a>></span><span class="txt">{{slideHdName}}</span>
</div>
</div>
<div class="listContainer">
<div class="detailCont" v-html="pageCont">
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</template>
<script>
import { getChildChannel, getChannelInfo, getArtInfoByChannel,increaseViews} from '@/api/common'

export default {
  name: 'list',
  data(){
    return {
      slideHdTitle: '',
      viewSlideHdParent: true,
      slideHdParentName: '',
      slideHdParentUrl: '',
      slideHdName: '',
      hdTitle: '',
      hdName: '',
      pageCont: '',
      active: 0,
      slideHd: {
        title: '新闻动态',
      },
      slideList:[],
      pageSize: 10,
      pageNum: 1,
      total: 0,
      listData: [
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
          artDesc: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神集团公司机关纪委认真学习贯彻党的十九届六中全会精神集团公司机关纪委认真学习贯彻党的十九届六中全会精神集团公司机关纪委认真学习贯彻党的十九届六中全会精神集团公司机关纪委认真学习贯彻党的十九届六中全会精神'
        }]
    }
  },
  mounted(){
    var _this = this
    _this.active = _this.$route.params.id
    _this.listId = _this.$route.params.id
    // _this.getChildChannel()
    _this.getChannelInfo()
    _this.getArtInfoByChannel()
  },
  methods: {
    // 查询二级栏目
    getChildChannel(id) {
      var _this = this

      let data = {
        applicationUuid: _this.webId,
        parentUuid: id
      }
      getChildChannel(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.slideList = data
          if (_this.slideList.length) {
            _this.viewSlideHdParent = true
            let url = ''
            if (_this.slideList[0].catalog === 1) {
              url = `/listPage/${_this.slideList[0].entTChannelUuid}`
            } else if (_this.slideList[0].catalog === 2) {
              url = `/list/${_this.slideList[0].entTChannelUuid}`
            } else {
              url = `/listPic/${_this.slideList[0].entTChannelUuid}`
            }
            _this.slideHdParentUrl = url
          } else {
            _this.viewSlideHdParent = false
          }
        }
      })
    },
    getChannelInfo() {
      var _this = this
      let data = {
        channelUuid: _this.$route.params.id
      }
      getChannelInfo(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.slideHdName = data.name
          if (data.parentUuid == "0") {
            _this.slideHdTitle = data.name
            _this.viewSlideHdParent = false
            return
          }

          _this.getChildChannel(data.parentUuid)

          let params = {
            channelUuid: data.parentUuid
          }
          getChannelInfo(params).then(res => {
            const { code, data } = res
            if (code === 1 && data) {
              _this.slideHdParentName = data.name
              _this.slideHdTitle = data.name
            }
          })
        }
      })
    },
    // 
    getArtInfoByChannel() {
      var _this = this
      let data = {
        entTApplicationUuid: _this.webId,
        channelUuid: _this.$route.params.id
      }
      getArtInfoByChannel(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.pageCont = data.content
          this.increaseViews(data.entTArticleUuid)
        }
      })
    },
    increaseViews(id){
      let data = {
        entTArticleUuid: id
      }
      increaseViews(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          // _this.dataView = data
          // console.log(111,_this.dataView)
        }
      })
    }
  }
}
</script>